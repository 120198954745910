.schedule .days{
    display: flex;
    flex-flow:row wrap;
    align-items: flex-start;
    justify-content: center;
}

.schedule .days > div {
    margin:2%;
    min-width: 35%;
}
@media only screen and (width > 1099px) {
    .schedule .days .day{
        min-width: 35%;
        max-width: 48%;
     
    }
}
@media only screen and (width < 550px) {
    .schedule .days .day{
        min-width: 98%;
    }
}
@media only screen and (width > 549px) and (width < 750px) {
    .schedule .days .day{
        min-width: 75%;
        max-width: 75%;
    }
}
@media only screen and (width > 750px) and (width < 1100px) {
    .schedule .days .day{
        
        max-width: 48%;
    }
}
.schedule .collapse {
    max-height:0;
    opacity: 0;
}

.schedule .panel{
    margin-left: 3%;
    margin-right: 3%;
}
.schedule .expand {
    max-height:50em;
    opacity: 1;
}

.schedule .schedule-detail {
    transition:max-height .3s linear, opacity .3s;
    
}

.schedule .details{
    background-color: transparent;
    border: none;
    font-size:1em;
    color:var(--blueAlt);
    cursor: pointer;
}

.schedule .details:hover{
    color:var(--grey-dark);
}

.schedule .button-space{
    padding:0.5em 0 0 0.25em;
}

.schedule .map-link {
    background-color: transparent;
    border:none;
    color:var(--grey-dark);
    font-weight: bold;
    font-size: 1.2em;
    cursor: pointer;
    margin-left: 3%;
    
}

.schedule .map-link:hover {
    color:var(--blue);
    
}

.schedule .button-details {
    padding:2% 0 0 2%;
}
