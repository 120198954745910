.App{
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  height:100%;
}

.App .app-content {
  /* max-height: 28em; */
  overflow-y: auto;
  
}

@media only screen and (width > 899px){
  .App .app-content {
    max-height:100%;
     
  }
}

