.maps {

}

.maps section{
    margin-left: 10%;
    margin-right: 10%;
}

.maps p {
    font-size: 1.2em;
    color:var(--grey-dark);
    font-weight: 700;
}

.maps ul li {
    margin-top:10%;
}

.maps ul li:first-child{
    margin-top:5%;
}

img {
    transition: opacity .3s;
}
