.resources {
    margin-bottom: 3em;
}
.resources ul {
    margin-top:10%;
    list-style-type: none;
}

.resources ul li {
    margin-top:3%;
}
.resources a{
    /* margin-left:3%; */
    font-size: 1.1em;
    color:var(--blue);
}

.resources ion-icon{
    color:var(--grey-dark);
    font-size: 1.3em;
    margin-top:25%;
}

