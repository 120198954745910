.mlt h2{
    font-size: 1.25em;
    color:var(--text);    
    text-align: center;
    font-weight: bold;
}

.mlt .mlt-list {
    display: flex;
    flex-flow: row wrap;
    gap: 30px 10px;
}

.mlt .leader{
    min-width: 20em;
}

@media only screen and (width < 1200px){
    .mlt .leader{
        min-width: 45%;
        max-width: 45%;
    }
}

@media only screen and (width < 800px){
    .mlt .leader{
        min-width: 98%;
        
    }
}
.mlt .mlt-list .leader .card-content{
    min-height: 15em;
}
@media only screen and (width > 1200px) {
    .mlt .mlt-list .leader {
        max-width: 24%;
    }    
}

.mlt .mlt-list button{
    font-weight: bold;
    margin:5% 0 8% 5%;
}

.mlt .open-bio{
    background-color: transparent;
    border:none;
    text-decoration: underline;
    color:var(--blueAlt);
    padding:0;
    margin:0;
}

img{
    transition: opacity .3s;
}