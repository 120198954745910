
.footer-main{
    background-color: var(--text);
    position: fixed;
    left:0;
    bottom: 0;
    width: 100%;    
    /* margin-top: auto; */    
}

@media only screen and (width > 899px) {
    .footer-main{
        display: none;
        position: relative;        
        width: 100%;    
        /* margin-top: auto; */    
    }
}


.footer-main ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top:2%;
}

.footer-main ul li {
    color:var(--green);    
    margin-left:10%;    
    text-align: center;
    
}
.footer-main ul li.is-active{
    color:#ffffff;
}
.footer-main ul:first-child{
    margin-left:0;
    margin-right:10%;
}
.footer-main ul li a{
    color:var(--green);
    font-weight: bold;
    font-size: 1em;
    
}
.footer-main ul li a.is-active{
    color:#ffffff;
}
.footer-main ul li ion-icon{
    margin-top:5px;
    min-height: 1em;
    max-height: 1em;
}
