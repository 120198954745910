.main-header{
    
}
.main-header #hero {
    object-fit: cover;
    
    /* background-image: url("../../public/img/VMLogoWeb-Mobile.jpg"); */
    background-repeat: no-repeat;    
    background-size: 100% 100%;
    max-width: 2000px;
    
}


.main-header header{
    /* background-color: var(--blue); */
    /* margin-bottom: 10%; */
}

.main-header h1{
    color:var(--primary);
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5em;
}

.main-header h2{
    color:var(--grey-dark);
    text-align: center;
    text-transform: uppercase;
    font-style:italic;
    font-size: 1.5em;
}

@media only screen and (max-width: 500px){
    .main-header #hero{
        min-height: 12rem;
        background-image: url("../../public/img/VMLogoWeb-Mobile.jpg");
        
    }

    .main-header h1 {
        font-size: 1.4em;
    }
    .main-header h2{
        font-size: 1.1em;
    }
}



@media only screen and (min-width: 501px) and (max-width: 900px){
    .main-header #hero{
        min-height: 22rem;
        background-image: url("../../public/img/VMLogoWeb-Medium.jpg");
        
    }
}

@media only screen and (min-width:901px) {
    .main-header #hero{
        min-height: 30rem;
        background-image: url("../../public/img/VMLogoWeb-Large.jpg");
        
    }
    .main-header h1{
        
    }
}

@media only screen and (min-width:2200px){
    .main-header #hero
{
    margin-left: auto;
    margin-right: auto;
}   
}

.main-header ul {
    color:#ffffff;
    font-size:1em;
    display: inline;
    position:relative;
    top:1em;
}

@media only screen and (width < 901px){
    .main-header ul{
        display: none;
    }
}
.main-header ul li {
    float:left;
    margin-left:1%;
    padding:1%;
    background-color: rgba(65,65,65, 0.8);
    border-radius: 10%;
    cursor: pointer;
    transition: background-color .1s;
}

.main-header ul li.is-active{
    background-color: var(--blue);
}

.main-header ul li:hover{
    background-color: var(--blue);
}

.main-header ul li a{
    color:#fff;
    font-size: 1em;
    
}

