

.hide {
    display:none;
}
/* 
.table tr td:nth-child(2){
    border-right: solid 3px #000;
    
}  */

.spacer{
    min-height: 1em;
}