.speakers .speaker-list {
    display: flex;
    flex-flow: row wrap;
    gap: 30px 15px;
}

@media only screen and (width < 600px) {
 
.speakers .speaker-list .speaker {
    min-width: 80%;
    max-width: 98%;
    margin:auto;
}   
}


@media only screen and (width > 600px) and (width < 1100px) {
 
    .speakers .speaker-list .speaker {
        max-width: 48%;
        
    }   
    }

@media only screen and (width > 1100px) and (width < 1400px){
    .speakers .speaker-list .speaker{
        max-width: 32%;
    }
    .speakers .speaker-list .speaker .card{
        min-height: 25rem;
    }
}

@media only screen and (width > 1399px){
    .speakers .speaker-list .speaker{
        max-width: 24%;
    }
    .speakers .speaker-list .speaker .card{
        min-height: 25rem;
    }
}

.speakers .content-bio  {
    
    display:-webkit-box;
    overflow:hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

img {
    transition: all .3s;
}