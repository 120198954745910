@charset "utf-8";

$blue:#3293D3;
$blueAlt:#2577AD;
$green: #c7e6d5;
$purple: #D33293;
$orange:#D37232;
$text:#414141;


$primary: $blue;
$secondary: $orange;
$info:$secondary;
$link: $blueAlt;
$text:$text;
$green: $green;
$grey-dark:#6b6b6b;

@font-face {
    font-family: Roboto;
    src: url(../public/fonts/Roboto-Regular.ttf);
}

@font-face{
    font-family: Monteserrat;
    src: url(../public/fonts/Montserrat.ttf);
}

$family-sans-serif:	Roboto, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$tabs-toggle-link-active-background-color: $blue;
// $tabs-toggle-link-active-border-color: $blue;
// $tabs-toggle-link-active-color: $green;

:root{
    --primary:#{$primary};
    --blue:#{$blue};
    --blueAlt:#{$blueAlt};
    --secondary:#{$secondary};
    --orange:#{$orange};
    --green:#{$green};
    --grey-dark:#{$grey-dark};
    --text:#{$text};
    --animationSpeed:.6s;
}

@import "../node_modules/bulma/bulma.sass";


h1.title {
    font-family: Monteserrat, sans-serif;
    font-size: 1.4em;

}

h2.subtitle {
    font-family: Monteserrat, sans-serif;
}



@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.invisible {
    opacity: 0;
}
.fade-in {
    animation: fade-in var(--animationSpeed);
}

.fade-out {
    animation: fade-out var(--animationSpeed);
}

.modal-fade-in {
    animation: fade-in .1s linear;
}
.modal-fade-out {
    animation: fade-out .2s linear;
}

.route-component{
    min-height: 55em;
}