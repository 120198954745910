.bio .main{
    max-width: 80%;
    margin:auto;
}

.bio h2{
    font-size: 1.2em;
    color:var(--blueAlt);
    font-weight: bold;
}

.bio h3{
    color:var(--grey-dark);
    font-size: 1.1em;
    font-style: italic;
    font-weight: bold;
}
.bio .close{
    position: relative;
    left:79%;
    
}