.breakout-meeting .main {
    max-width: 80%;
    margin:auto;
}

.breakout-meeting h2{
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    color:var(--blue);
}

.breakout-meeting h3{
    color:var(--grey-dark);
    text-align: center;
    margin-bottom: 2em;
}

.breakout-meeting .details{
    display: flex;
    justify-content: center;
}

.breakout-meeting .back{
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color:var(--blueAlt);
}
